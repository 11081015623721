@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}


/* to hide scroll bar */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
	/* for Webkit browsers */
}
  
.hide-scrollbar {
	-ms-overflow-style: none;
	/* for IE and Edge */
	scrollbar-width: none;
	/* for Firefox */
}

.thin-scrollbar {
overflow: auto; /* Ensure both horizontal and vertical scrollbars can be visible */
}

.thin-scrollbar::-webkit-scrollbar {
	width: 3px;
	height: 3px; /* For horizontal scrollbar */
}

.thin-scrollbar::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
	background: #888;
}

.thin-scrollbar::-webkit-scrollbar-thumb:hover {
	background: #555;
}



*::-webkit-scrollbar {
	width: 3px;
	height: 3px;
}

*::-webkit-scrollbar-track {
	background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
	background: #138b0f;
}

*::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* Reset styles for the specific div and its children */
.reset-styles, .reset-styles * {
	/* all: unset;
	display: revert; */
	font-family: revert;
	font-size: revert;
	font-style: revert;
	font-weight: revert;
	line-height: revert;
	letter-spacing: revert;
	text-align: revert;
	text-decoration: revert;
	text-transform: revert;
	white-space: revert;
	word-spacing: revert;
	/* background-color: revert; */
	border: revert;
	border-radius: revert;
	box-shadow: revert;
	/* color: revert; */
	/* cursor: revert; */
	outline: revert;
	/* padding: revert;
	margin: revert;
	height: revert;
	width: revert; */
	list-style-type: revert; /* Ensure default list styles are applied */
	list-style-position: revert;
	list-style-image: revert;
	overflow: revert;
  }
  
  /* Ensure specific list item styles if needed */
  .reset-styles ul, .reset-styles ol {
	margin: 5px 20px; /* Add spacing between list items */
  }
  
  .demo-editor.rdw-editor-main {
	padding: 0px 15px;
	width: 100%;
	height: auto;
	min-height: 150px;
  }
  .PhoneInputInput:focus-visible {
	outline: none !important;
  }